<template>
    <div>
        <div class="box">
            <h2>{{ $t('gocardless.payments') }}</h2>

            <LoadingSpinner class="col-12" v-if="loading" />
            <template v-else>
                <CustomTable
                    id_table="gocardless_payment"
                    :items="payments"
                    sortBy="charge_date"
                    primaryKey="id"
                    :hasExportOption="false"
                    :showPagination="false"
                    :checkboxes="false"
                />
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Gocardless from '@/mixins/Gocardless.js'
    
    export default {
        name: "GocardlessPayment",
        mixins: [Gocardless],
        data () {
            return {
                loading: false,
                payments: []
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loading = true
                const pay = await this.getPayments()
                this.payments = this.format_payment(pay)
                this.loading = false
            },
            format_payment(payments) {
                let p = []
                if(!payments) {
                    return []
                }

                for(let i = 0; i < payments.length; i++) {
                    let temp = payments[i]
                    temp.status = this.getTrad('gocardless.payments_'+payments[i].status)
                    p.push(temp)
                }

                return p
            },
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		    CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
        }
    }
</script>
