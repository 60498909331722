import Constants from 'Constants'

export default {
    methods: {
        getBankAccounts: async function() {
            const url = Constants.GOCARDLESS_BANK_ACCOUNT+`?licence_key=${Constants.USER_LICENCE_KEY}`
            
            return this.$request.request_get_api("GocardlessMixin::getBankAccounts", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("GocardlessMixin::getBankAccounts => ", e)
                    return null
                })
        },

        getMandates: async function() {
            const url = Constants.GOCARDLESS_MANDATE+`?licence_key=${Constants.USER_LICENCE_KEY}`
            
            return this.$request.request_get_api("GocardlessMixin::getMandates", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("GocardlessMixin::getMandates => ", e)
                    return null
                })
        },

        addMandate: async function() {
            const url = Constants.GOCARDLESS_MANDATE+`?licence_key=${Constants.USER_LICENCE_KEY}`
            
            return this.$request.request_post_api("GocardlessMixin::addMandate", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("GocardlessMixin::addMandate => ", e)
                    return null
                })
        },

        addMandateComplete: async function(mandate_id) {
            const url = this.constructRoute(Constants.GOCARDLESS_MANDATE_COMPLETE_ID, {mandate_id: mandate_id})+`?licence_key=${Constants.USER_LICENCE_KEY}`
            
            return this.$request.request_post_api("GocardlessMixin::addMandateComplete", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("GocardlessMixin::addMandateComplete => ", e)
                    return null
                })
        },

        getPayments: async function() {
            const url = Constants.GOCARDLESS_PAYMENT+`?licence_key=${Constants.USER_LICENCE_KEY}`
            
            return this.$request.request_get_api("GocardlessMixin::getPayments", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("GocardlessMixin::getPayments => ", e)
                    return null
                })
        },
    }
}